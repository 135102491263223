import React from 'react';
import styled from 'styled-components';
import SoftwareDevelopment from './SoftwareDevelopment';
import Branding from './Branding';


const Container = styled.div`
  display: grid;
  width: 100%;
  padding: 90px 30px 40px;
  grid-gap: 100px;
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.large}){
    grid-template-columns: 1fr 2fr;
    grid-gap: 0;
  }
`;


const WhatWeDo = () => (
  <Container>
    <SoftwareDevelopment />
    <Branding />
  </Container>
);

export default WhatWeDo;
