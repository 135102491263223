import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';


/** The margin-top: -1px is there to really fit in two SVG's on theirs' borders. */
const StyledHalfHex = styled.div<{ backgroundimage: string }>`
  background-image: ${({ backgroundimage }) => `url(${backgroundimage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 100%;
  width: 100%;
  margin-top: -1px;
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    display: none;
  }
`;

const HalfHex = (props: object) => {
  const data = useStaticQuery(graphql`
    {
      halfHexSvg: file(name: {eq: "halfHex"}) {
        publicURL
      }
    }
  `);

  return (
    <div style={{ position: 'relative' }}>
      <StyledHalfHex backgroundimage={data.halfHexSvg.publicURL} {...props} />
    </div>
  );
};

export default HalfHex;
