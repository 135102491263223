import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { useMdxTranslation } from '../../../hooks/useMdxTranslation';


const TextWrapper = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  z-index: 100;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  font-size: 1em;
  transform: translateY(-180px);
  padding: 0 20px 50px;

  p {
    margin-bottom: 10px;
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    transform: translateY(0);
    text-align: left;
    align-items: flex-start;
    padding: 0;
    margin-left: 40px;
    width: 48%;
    font-size: 1.2em;
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.medium}) {
    font-size: 1.48em;
    transform: translate(35px, -45px);

    p {
      margin-bottom: 20px;
    }
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.large}) {
    font-size: 1.6em;
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.extraLarge}) {
    width: 55%;
    font-size: 1.8em;
  }
`;


const IntroductionMd = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativePath: {regex: "/^../home/rightPlace.md$/"}}) {
        edges {
          node {
            relativePath
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `);

  const translationObject = useMdxTranslation(data);
  return (
    <TextWrapper
      className="rightPlaceText"
      dangerouslySetInnerHTML={{
        __html: translationObject.childMarkdownRemark.html,
      }}
    />
  );
};

export default IntroductionMd;
