import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { clientsLogos } from '../constants';


interface File {
  publicURL: string,
  name: string,
  fileName: string,
}

interface Logo {
  fileName: string,
  publicURL: string,
  externalLink: string,
}

function useLogoList(): Logo[] {
  const { allFile } = useStaticQuery<ListQuery<File>>(graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "trustedUsLogos"}}) {
        nodes {
          publicURL
          name
        }
      }
    }
  `);
  const logosFromAssets = allFile.nodes;

  return useMemo(() => {
    const namePublicUrlMap = logosFromAssets.reduce<Record<string, string>>(
      (prevValue, currentLogo: File) => ({
        ...prevValue,
        [currentLogo.name]: currentLogo.publicURL,
        fileName: currentLogo.name,
      }),
      {},
    );

    const unassignedLogos: File[] = [];

    /* This fragment checks if there are some images of logos that are not included in clientsLogos.
     If there are such, they are added at the end of the list */
    logosFromAssets.forEach((logoFromAssets: File) => {
      let foundName: boolean = false;
      clientsLogos.forEach((clientsLogo) => {
        if (clientsLogo.fileName === logoFromAssets.fileName) {
          foundName = true;
        }
      });

      if (!foundName) {
        unassignedLogos.push(logoFromAssets);
      }
    });


    /** If some logos names haven't been found in the constants/clientsLogos,
     * during memoizedListcheck function, we left the link as an empty string
     * and in LogoSlider we make this logos not clickable */
    const unassignedLogosUpdated = unassignedLogos.map((unassignedLogo) => ({
      ...unassignedLogo,
      externalLink: '',
    }));
    const clientsLogosUpdated = clientsLogos.concat(unassignedLogosUpdated);

    /** In this function we first check if there is an image ready for our logo,
     if there is not we simply skip that logo. */
    return clientsLogosUpdated.filter((clientsLogo) => (
      namePublicUrlMap[clientsLogo.fileName] !== undefined)).map((clientsLogo) => ({
      ...clientsLogo,
      fileName: clientsLogo.fileName,
      publicURL: namePublicUrlMap[clientsLogo.fileName],
    }));
  }, [logosFromAssets]);
}


export default useLogoList;
