import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import ButtonsSection from './ButtonsSection';
import AboutUs from './AboutUs';
import Introduction from './Introduction';
import TrustedUs from './TrustedUs';
import ContactUsButton from '../../components/ContactUsButton';
import useWindowWidth from '../../hooks/useWindowWidth';
import useContactBtnPosition from '../../hooks/useContactBtnPosition';
import WhatWeDo from './WhatWeDo';
// import ClientsOpinions from './ClientsOpinions';


const Wrapper = styled.div`
  min-height: 100vh;
  position: relative;
`;

const HomeLayout = () => {
  const windowWidth = useWindowWidth();
  const { screenSizeWidthWithoutPx } = useContext(ThemeContext);

  const position = useContactBtnPosition({ position: 'absolute' });

  return (
    <Wrapper>
      <ButtonsSection />
      <Introduction />
      <WhatWeDo />
      <AboutUs />
      <TrustedUs />
      {/* this section will be added to the website when we'll have enough testimonials */}
      {/* <ClientsOpinions /> */}
      {windowWidth !== 0 && windowWidth >= screenSizeWidthWithoutPx.small
      && (
        <ContactUsButton
          linkAddress="contact"
          width={100}
          height={85}
          position={position.position}
          bottom={position.bottom}
          top={position.top}
          right="15px"
        />
      )}
    </Wrapper>
  );
};

export default HomeLayout;
