import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';


const MikolajWrapper = styled.div<{backgroundimage: string}>`
  background-image: ${props => `url(${props.backgroundimage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 310px;
  width: 310px;
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    height: 350px;
    width: 350px;
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.medium}) {
    height: 400px;
    width: 400px;
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.large}) {
    height: 500px;
    width: 500px;
  }

  @media(min-width: ${({ theme }) => theme.screenSizeWidth.extraLarge}) {
    height: 550px;
    width: 550px;
  }
`;

const MikolajSVG = () => {
  const data = useStaticQuery(graphql`
      query {
        file(name: { eq: "mikolaj" }) {
          publicURL
        }
      }
    `);

  return (
    <MikolajWrapper backgroundimage={data.file.publicURL} />
  );
};

export default MikolajSVG;

