import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import AboutUsMd from './AboutUsMd';
import BlueHex from './BlueHex';
import HalfHex from './HalfHex';
import StyledWombatContainer from './StyledWombatContainer';


const DescriptionContainer = styled.div`
  color: ${({ theme }) => theme.colors.white};
  padding: 0 15px 30px 30px;
  width: 90%;
  
  p:last-child {
    margin-bottom: 0;
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.extraSmall}) {
    padding: 0 0 50px 50px;
  }

  @media(min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    width: 100%;
    padding: 0;
    text-align: center;
    font-size: 1em;
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.extraLarge}) {
    font-size: 1.1em;
  }
`;

const AboutUsHeader = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  padding: 30px 0 0 30px;
     
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.extraSmall}) {
    padding: 50px 0 0 50px;
  }
   
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    margin-bottom: 20px;
    padding: 0;
    text-align: center;
  }
`;

const AboutUs = () => {
  const { t } = useTranslation();


  return (
    <>
      <StyledWombatContainer id="aboutUs">
        <BlueHex>
          <AboutUsHeader>
            {t('aboutUs')}
          </AboutUsHeader>
          <DescriptionContainer>
            <AboutUsMd />
          </DescriptionContainer>
        </BlueHex>
      </StyledWombatContainer>
      <HalfHex>
        <AboutUsHeader>
          {t('aboutUs')}
        </AboutUsHeader>
        <DescriptionContainer>
          <AboutUsMd />
        </DescriptionContainer>
      </HalfHex>
    </>
  );
};

export default AboutUs;
