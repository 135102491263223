import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import StyledTitle from '../../../components/StyledTitle';
import WhatWeDoContent from '../../../components/WhatWeDoContent';


const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.cleanGray};
  color: ${({ theme }) => theme.colors.darkGray};
`;

const Subtitle = styled.h3`
  font-family: "Lato", sans-serif;
  color: ${({ theme }) => theme.colors.lightGray};
  margin-bottom: 10px;
`;

const Branding = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <StyledTitle title={t('whatWeDo.secondSection.title')} />
      <WhatWeDoContent hasTwoColumns>
        <div>
          <Subtitle>
            {t('whatWeDo.secondSection.firstSubsection.title')}
          </Subtitle>
          <p>
            {t('whatWeDo.secondSection.firstSubsection.content')}
          </p>
        </div>
        <div>
          <Subtitle>
            {t('whatWeDo.secondSection.secondSubsection.title')}
          </Subtitle>
          <p>
            {t('whatWeDo.secondSection.secondSubsection.content')}
          </p>
        </div>
        <div>
          <Subtitle>
            {t('whatWeDo.secondSection.thirdSubsection.title')}
          </Subtitle>
          <p>
            {t('whatWeDo.secondSection.thirdSubsection.content')}
          </p>
        </div>
        <div>
          <Subtitle>
            {t('whatWeDo.secondSection.fourthSubsection.title')}
          </Subtitle>
          <p>
            {t('whatWeDo.secondSection.fourthSubsection.content')}
          </p>
        </div>
      </WhatWeDoContent>
    </Container>
  );
};

export default Branding;
