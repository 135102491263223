import React from 'react';
import styled from 'styled-components';
import SvgOpenMail from './fromSvg/OpenMail';
import HexInternalLink from './HexInternalLink';
import { getLinkAdres } from '../utils/miscelenaus';


interface StyledHexInternalLinkProps {
  width: number,
  height: number,
  left?: string,
  right?: string,
  top?: string,
  bottom?: string,
  position: string,
}

interface ContactUsButtonProps extends StyledHexInternalLinkProps {
  color?: string,
  linkAddress: PageType,
}

const StyledHexInternalLink = styled(HexInternalLink)<StyledHexInternalLinkProps>`
  position: ${({ position }) => position};
  right: ${({ right }) => (right ?? 'unset')};
  left: ${({ left }) => (left ?? 'unset')};
  bottom: ${({ bottom }) => (bottom ?? 'unset')};
  top: ${({ top }) => (top ?? 'unset')};
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
`;

/**
  in SvgOpenMail we have width and height multiplied by 0,6
  this action allow as to style only HexButton size and in the same time
  fit in inside svgr size.
 */

const ContactUsButton = ({
  position, top, bottom, left, right, width, height, color, linkAddress,
}: ContactUsButtonProps) => (
  <StyledHexInternalLink
    to={getLinkAdres(linkAddress)}
    width={width}
    height={height}
    left={left}
    right={right}
    top={top}
    bottom={bottom}
    position={position}
    type="redHex"
  >
    <SvgOpenMail
      width={width * 0.6}
      height={height * 0.6}
      color={color}
    />
  </StyledHexInternalLink>
);

export default ContactUsButton;
