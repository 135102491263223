import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Hello from './Hello';


const WombatWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledHelloWombat = styled.div<{ backgroundimage: string }>`
  background-image: ${({ backgroundimage }) => `url(${backgroundimage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 160px;
  width: 180px;
  margin-top: 20px;
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.medium}) {
    height: 180px;
    width: 200px;
    margin-top: 35px;
  }
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.large}) {
    height: 200px;
    width: 230px;
    margin-top: 40px;
  }
`;

const HelloWombat = (props: object) => {
  const data = useStaticQuery(graphql`
      query {
        wombatSvg: file(name: { eq: "wombatLogo" }) {
          publicURL
        }
      }
    `);

  return (
    <WombatWrapper>
      <StyledHelloWombat backgroundimage={data.wombatSvg.publicURL} {...props} />
      <Hello />
    </WombatWrapper>
  );
};

export default HelloWombat;
