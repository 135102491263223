import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useMdxTranslation } from '../../../hooks/useMdxTranslation';


const AboutUsMd = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativePath: {regex: "/^../home/aboutUs.md$/"}}) {
        edges {
          node {
            relativePath
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `);

  const translationObject = useMdxTranslation(data);
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: translationObject.childMarkdownRemark.html,
      }}
    />
  );
};

export default AboutUsMd;
