import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import BarWithMikolaj from './BarWithMikolaj';
import IntroductionMd from './IntroductionMd';
import DecorationsSilver from '../../../components/DecorationsSilver';
import useWindowWidth from '../../../hooks/useWindowWidth';


/** this margin on minus is here to place Mikolaj SVG closer to the buttons. */
const IntroductionWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: -360px;
  position: relative;
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    margin-top: -205px;
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.large}) {
    margin-top: -300px;
  }
`;

const Spacer = styled.div`
  width: 250px;
  height: 240px;
`;

const Introduction = () => {
  const windowWidth = useWindowWidth();
  const { screenSizeWidthWithoutPx } = useContext(ThemeContext);

  return (
    <IntroductionWrapper>
      {windowWidth !== 0 && (
        <>
          {windowWidth < screenSizeWidthWithoutPx.small && (<Spacer id="intro" />)}
          <IntroductionMd />
          <BarWithMikolaj />
          {windowWidth >= screenSizeWidthWithoutPx.small && (
            <DecorationsSilver
              height={windowWidth >= screenSizeWidthWithoutPx.medium ? '225px' : '200px'}
              width={windowWidth >= screenSizeWidthWithoutPx.medium ? '30%' : '40%'}
              side="left"
              top={windowWidth >= screenSizeWidthWithoutPx.medium ? '-100px' : '-50px'}
            />
          )}
        </>
      )}
    </IntroductionWrapper>
  );
};
export default Introduction;
