import React from 'react';
import {
  graphql, useStaticQuery,
} from 'gatsby';
import styled from 'styled-components';


const StyledSlidingHand = styled.div<{ backgroundimage: string }>`
  background-image: ${({ backgroundimage }) => `url(${backgroundimage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const SlidingHand = (props: object) => {
  const data = useStaticQuery<{ file: {publicURL: string }}>(graphql`
      query {
        file(name: { eq: "redHand" }) {
          publicURL
        }
      }
    `);

  return (
    <StyledSlidingHand backgroundimage={data.file.publicURL} {...props} />
  );
};

export default SlidingHand;
