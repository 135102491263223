import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { useMdxTranslation } from '../../../hooks/useMdxTranslation';


const TextConteiner = styled.div`
  width: 100%;
  padding: 0 30px 0 30px;
  text-align: center;
  font-size: 1em;
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    padding: 0;
    color: ${({ theme }) => theme.colors.white};
    width: 50%;
    font-size: 1.3em;
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.xExtraLarge}) {
    padding: 0 40px;
  }
`;

const AboutUsMd = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativePath: {regex: "/^../home/trustedUs.md$/"}}) {
        edges {
          node {
            relativePath
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `);

  const translationObject = useMdxTranslation(data);
  return (
    <TextConteiner
      dangerouslySetInnerHTML={{
        __html: translationObject.childMarkdownRemark.html,
      }}
    />
  );
};

export default AboutUsMd;
