import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';


interface IProps {
  children: object,
  id?: string,
}
const WombatContainer = ({ children, id, ...otherProps }: IProps) => {
  const data = useStaticQuery(graphql`
      query {
        image: file(name: { eq: "wombat_skeleton_cropped" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `);

  return (
    <BackgroundImage
      Tag="div"
      fluid={data.image.childImageSharp.fluid}
      id={id}
      {...otherProps}
    >
      {children}
    </BackgroundImage>
  );
};

const StyledWombatContainer = styled(WombatContainer)`
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: ${({ theme }) => theme.colors.white};
  display: none;

  @media(min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
    margin: 50px 0;
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.medium}) {
    height: 600px;
  }
    
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.large}) {
    height: 650px;
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.extraLarge}) {
    height: 843px;
  }
`;

export default StyledWombatContainer;
