import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';


const StyledHello = styled.div<{ backgroundimage: string }>`
  background-image: ${({ backgroundimage }) => `url(${backgroundimage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 90px;
  height: 90px;
  margin-top: 54px;
  transform: translateX(-110px);
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.medium}) {
    width: 100px;
    height: 100px;
    margin-top: 74px;
    transform: translateX(-125px);
  }
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.large}) {
    width: 120px;
    height: 120px;
    margin-top: 80px;
    transform: translateX(-145px);
  }
`;

const Hello = (props: object) => {
  const data = useStaticQuery(graphql`
      query {
        helloSvg: file(name: { eq: "hello" }) {
          publicURL
        }
      }
    `);

  return (
    <StyledHello backgroundimage={data.helloSvg.publicURL} {...props} />
  );
};

export default Hello;
