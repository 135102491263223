import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import HelloWombat from './HelloWombat';
import HomeButtons from './HomeButtons';
import { navbarMargin } from '../../../constants';
import HexInternalLink from '../../../components/HexInternalLink';
import ShadowLights from '../../../components/ShadowLights';
import useWindowWidth from '../../../hooks/useWindowWidth';
import ArrowDown from '../../../components/ArrowDown';


const PageTitle = styled.h1`
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
  width: 100%;
  font-size: 1.6em;
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    width: 410px;
    font-size: 2em;
  }
`;

const PageSubTitle = styled.h2`
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  margin: 0;
  font-size: 1.06em;
  line-height: 20px;
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    font-size: 1.5em;
    line-height: 50px;
  }
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MessagesWrapper = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 25px;
  font-size: 0.7em;
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.medium}) {
    font-size: 1em;
  }
`;

const IdeaTextWrapper = styled.span`
  margin: 0;
  font-size: 1.5em;
`;

const MainWrapper = styled.div`
  padding-top: ${navbarMargin};
  padding-bottom: 800px;
 
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    padding-bottom: 750px;
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.medium}) {
    padding-bottom: 900px;
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.large}) {
    padding-bottom: 1000px;
  }

  @media(min-width: ${({ theme }) => theme.screenSizeWidth.xExtraLarge}) {
    padding-bottom: 1100px;
  }
`;

const GotIdeaButton = styled(HexInternalLink)`
  position: absolute;
  height: 160px;
  width: 200px;
  top: 440px;
  right: 5%;
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.extraSmall}) {
    height: 200px;
    width: 220px;
    top: 420px;
    right: 20%;
  }
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    height: 210px;
    width: 240px;
    top: 350px;
    right: 5%;
  }
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.medium}) {
    height: 250px;
    width: 280px;
    right: 5%;
  }
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.large}) {
    height: 260px;
    width: 300px;
    top: 380px;
  }
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.extraLarge}) {
    right: 10%;
  }
`;

const StyledRedHexInternalLink = styled(HexInternalLink)`
  position: absolute;
  height: 110px;
  width: 130px;
  left: 5%;
  top: 370px;
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.extraSmall}) {
    left: 15%;
    height: 130px;
    width: 160px;
  }
`;

const StyledArrowDown = styled(ArrowDown)`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 25px;
  top: 680px;
  left: 0;
  z-index: 2;
`;

const ButtonsSection = () => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();
  const { screenSizeWidthWithoutPx } = useContext(ThemeContext);

  return (
    <MainWrapper>
      <ShadowLights />
      <PageHeader>
        <PageTitle>
          {t('commonWombat')}
        </PageTitle>
        <PageSubTitle>
          {t('brandAndSoftwareDevelopment')}
        </PageSubTitle>
      </PageHeader>
      <HelloWombat />
      <>
        <GotIdeaButton
          type="redHex"
          to="contact"
        >
          <MessagesWrapper>
            <Trans i18nKey="gotAnIdeaWriteToUs_wordbreak">
              got
              <IdeaTextWrapper className="ideaTextWrapper">
                anIdea
              </IdeaTextWrapper>
              writeToUs
            </Trans>
          </MessagesWrapper>
        </GotIdeaButton>
      </>
      {windowWidth !== 0 && windowWidth >= screenSizeWidthWithoutPx.small ? (
        <HomeButtons />
      ) : (
        <>
          <StyledRedHexInternalLink
            type="redHex"
            to="portfolio"
          >
            <MessagesWrapper>
              {t('portfolio')}
            </MessagesWrapper>
          </StyledRedHexInternalLink>
          <StyledArrowDown
            to="#intro"
          />
        </>
      )}
    </MainWrapper>
  );
};

export default ButtonsSection;
