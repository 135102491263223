import { useState, useEffect } from 'react';


const useContactBtnPosition = (initialPosition: IPosition) => {
  const [position, setPosition] = useState(initialPosition);
  const getRectTop = (el: HTMLElement) => el.getBoundingClientRect().top;
  const getRectBottom = (el: HTMLElement) => el.getBoundingClientRect().bottom;

  useEffect(() => {
    const footer = document.querySelector('footer') as HTMLElement;
    const rightPlace = document.querySelector('#barWithMikolaj') as HTMLElement;
    const stopScrollingHomePosition = initialPosition.position === 'absolute'
      ? Math.round(getRectBottom(rightPlace) - getRectTop(document.body)) : 0;

    const handleScroll = () => {
      if (initialPosition.position !== 'absolute') {
        if (document.body.scrollTop + window.innerHeight
          > (getRectTop(footer) + document.body.scrollTop + 69)) {
          setPosition({ position: 'absolute', top: 'unset', bottom: '-43px' });
        }
        if (document.body.scrollTop + window.innerHeight
          < (getRectTop(footer) + document.body.scrollTop + 69)) {
          // restore fixed position when scroll up
          setPosition({
            position: initialPosition.position,
            top: initialPosition.top,
            bottom: initialPosition.bottom,
          });
        }
      } else {
        if (document.body.scrollTop + window.innerHeight
          > (getRectBottom(rightPlace) + document.body.scrollTop) + 69) {
          setPosition({ position: 'fixed', top: 'unset', bottom: '26px' });
        }
        if (document.body.scrollTop + window.innerHeight
          < ((getRectBottom(rightPlace) + document.body.scrollTop) + 69)) {
          setPosition({
            position: 'absolute',
            top: `${stopScrollingHomePosition - 43}px`,
            bottom: 'unset',
          });
        }
        if (document.body.scrollTop + window.innerHeight
          > (getRectTop(footer) + document.body.scrollTop + 69)) {
          setPosition({ position: 'absolute', top: 'unset', bottom: '-43px' });
        }
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [initialPosition]);
  return position;
};

export default useContactBtnPosition;
