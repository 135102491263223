import React from 'react';
import styled from 'styled-components';
import MikolajSVG from './MikolajSVG';


const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 101px;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    height: 114px;
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.medium}) {
    height: 130px;
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.large}) {
    height: 162px;
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.extraLarge}) {
    height: 179px;
  }
`;

const MikolajWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 0;
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    justify-content: flex-end;
    right: 20px;
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.medium}) {
    right: 40px;
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.large}) {
    right: 80px;
  }
`;


const BarWithMikolaj = ({ ...props }) => (
  <StyledWrapper {...props} id="barWithMikolaj">
    <MikolajWrapper>
      <MikolajSVG />
    </MikolajWrapper>
  </StyledWrapper>
);

export default BarWithMikolaj;

