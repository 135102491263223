import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import TransparentGridSpacer from './TransparentGridSpacer';
import HexInternalLink from '../HexInternalLink';


const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 350px; 
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.medium}) {
    width: 550px; 
  }
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.large}) {
    width: 600px; 
  }
`;

const StyleTransparentGridSpacer = styled(TransparentGridSpacer)`
  height: 260px;
  width: 185px;
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    height: 250px;
    width: 150px;
  }
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.medium}) {
    height: 280px;
    width: 200px;
  }
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.large}) {
    height: 290px;
    width: 220px;
  }
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.extraLarge}) {
    height: 300px;
    width: 230px;
  }
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.xExtraLarge}) {
    height: 270px;
    width: 260px;
  }
`;

const StyledRedHexInternalLink = styled(HexInternalLink)`
  height: 120px;
  width: 130px;
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.medium}) {
    height: 150px;
    width: 170px;
  }
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.large}) {
    height: 180px;
    width: 200px;
  }
`;

const HexCaption = styled.span`
  font-size: 0.7em;
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.medium}) {
    font-size: 1em;
  }
`;

interface HomeHexGridProps {
  internalLinks: Array<{
    text: string,
    link: string,
  }>,
}

/** This array is here to tell before which hex,
 * a blank spaces should be.

 So in this case, before second and third hex.
 */
const blankIndices = [1, 2];

function HomeHexGrid({
  internalLinks,
  ...otherProps
}: HomeHexGridProps) {
  const { t } = useTranslation();

  return (
    <ButtonWrapper {...otherProps}>
      {internalLinks.map((internalLink, index) => (
        <Fragment key={internalLink.text}>
          {blankIndices.includes(index) && (
            <>
              <StyleTransparentGridSpacer
                isGrid
              />
              <StyleTransparentGridSpacer
                isGrid
              />
              <StyleTransparentGridSpacer
                isGrid
              />
            </>
          )}
          <StyledRedHexInternalLink
            to={internalLink.link}
            type="redHex"
            isGrid
          >
            <HexCaption>
              {t(internalLink.text)}
            </HexCaption>
          </StyledRedHexInternalLink>
        </Fragment>
      ))}
    </ButtonWrapper>
  );
}

export default HomeHexGrid;
