import React from 'react';
import styled from 'styled-components';


const Background = styled.div<{ isGrid: boolean }>`
  height: 250px;
  width: 250px;
  margin: ${({ isGrid }) => (isGrid ? '-66px -23px' : '')};
  user-select: none;
`;

interface TransparentGridSpacerprops {
  isGrid: boolean,
}
const TransparentGridSpacer = ({ isGrid, ...props }: TransparentGridSpacerprops) => (
  <Background
    isGrid={isGrid}
    {...props}
  />
);


export default TransparentGridSpacer;
