import React from 'react';
import styled from 'styled-components';
import HomeHexGrid from '../../../components/hexButtonGrids/HomeHexGrid';
import { getLinkAdres } from '../../../utils/miscelenaus';


const HexGridWrapper = styled.div`
  position: absolute;
  top: 370px;
  left: 13%;
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    left: 15%;
    top: 340px;
  }
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.medium}) {
    left: 16%;
    top: 370px;
  }
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.large}) {
    left: 13%;
  }
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.extraLarge}) {
    left: 14%;
  }
`;


const HomeButtons = () => {
  const internalLinks = [
    {
      text: 'portfolio',
      link: getLinkAdres('portfolio'),
    },
    {
      text: 'aboutUs',
      link: '#aboutUs',
    },
    {
      text: 'trustedBy',
      link: '#trustedBy',
    },
  ];
  return (
    <HexGridWrapper>
      <HomeHexGrid
        internalLinks={internalLinks}
      />
    </HexGridWrapper>
  );
};

export default HomeButtons;
