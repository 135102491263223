import React from 'react';
import styled from 'styled-components';


const Title = styled.h2`
  margin: 10px auto 0;
  width: 14rem;
  font-size: 1.55rem;
  font-family: "Lato", sans-serif;
  color: ${({ theme }) => theme.colors.darkBlue};
  border: 5px solid ${({ theme }) => theme.colors.darkBlue};
  padding: 10px;
  position: relative;
  top: 0;
  transform: translateY(-47%);
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.large}) {
    margin: 0;
    transform: translate(10%, -40%);
  }
`;

interface IProps {
  title: string,
}

const StyledTitle = ({ title }: IProps) => (
  <Title className="whatWeDoTitle">
    {title}
  </Title>
);

export default StyledTitle;
