import * as React from 'react';


interface SvgOpenMailProps {
  width: number,
  height: number,
  color?: string,
}

const SvgOpenMail = ({ width, height, color = 'white' }: SvgOpenMailProps) => (
  <svg width={width} height={height} viewBox="0 0 100 85">
    <g opacity={0.9} fill={color}>
      <path d="M18 35v-7h63v7h15.949L49.398.264 1.638 34.615 1.89 35zM99.127 36.178L50.973 70.722 69.671 84.66l13.342-.02 16.145-48.439zM.758 36H.545l15.218 48.84 51.58-.127-38.886-28.842zM97.847 36H81v11.584zM2.588 36L18 47.061V36z" />
    </g>
  </svg>
);

export default SvgOpenMail;
