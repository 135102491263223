import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';
import SlidingHand from './SlidingHand';
import useLogoList from '../../../hooks/useLogoList';


const slideShowSpeed: string = '25000ms';
const wrapperSlideInSpeed: string = '8000ms';
const wrapperSlideInSpeedSmall: string = '1000ms';
const slideShowDelay: string = '8150ms';
const slideShowDelaySmall: string = '1700ms';
const slidingHandSpeed: string = '1000ms';
const slidingHandSpeedSmall: string = '1000ms';

const wrapperSlideIn = keyframes`
  0% {
    transform: translateX(-65%);
  }
  100% {
    transform: translateX(0);
  }
`;

const wrapperSlideInSmall = keyframes`
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(0);
  }
`;

const slidingHand = keyframes`
  0% {
    margin-right: 0;
    opacity: 1;
  }
  
  60% {
    opacity: 0.8;
  }
  
  85% {
    transform: translate(-58px);
  }

  100% {
    transform: translate(0);
    margin-right: -250px;
    opacity: 0.5;
  }
`;

const slidingHandSmall = keyframes`
  0% {
    margin-right: 0;
    opacity: 1;
  }
  
  60% {
    opacity: 0.8;
  }
  
  85% {
    transform: translate(-58px);
  }

  100% {
    transform: translate(0);
    margin-right: -200px;
    opacity: 0.5;
  }
`;

const slideShow = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const StyledSlidingHand = styled(SlidingHand)<{ animate: boolean }>`
  min-height: 250px;
  min-width: 200px;
  animation-name: ${slidingHandSmall};
  animation-duration: ${slidingHandSpeedSmall};
  animation-timing-function: cubic-bezier(0.5,0.05,0.53,1.01);
  animation-delay: ${wrapperSlideInSpeedSmall};
  animation-fill-mode: both;
  animation-play-state: ${({ animate }) => (animate ? 'running' : 'paused')};
  transform: translate(-58px);
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    animation-name: ${slidingHand};
    animation-duration: ${slidingHandSpeed};
    animation-delay: ${wrapperSlideInSpeed};
    min-height: 405px;
    min-width: 250px;
  }
`;

const SliderWrapper = styled.div<{ animate: boolean }>`
  padding-top: 50px;
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  animation-name: ${wrapperSlideInSmall};
  animation-duration: ${wrapperSlideInSpeedSmall};
  animation-timing-function: cubic-bezier(0.6,0.56,0.96,0.84);
  animation-fill-mode: both;
  animation-play-state: ${({ animate }) => (animate ? 'running' : 'paused')};
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    animation-duration: ${wrapperSlideInSpeed};
    animation-name: ${wrapperSlideIn};
    animation-timing-function: linear;
    height: 500px;
    padding-top: 100px;
  }
`;

const Slider = styled.div`
  width: 100%;
  height: 150px;
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 5px 5px rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    height: 250px;
  }
`;

const Slide = styled.div<{ animate: boolean }>`
  height: 100px;
  display: flex;
  align-items: center;
  animation-name: ${slideShow};
  animation-duration: ${slideShowSpeed};
  animation-delay: ${slideShowDelaySmall};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: reverse;
  animation-play-state: ${({ animate }) => (animate ? 'running' : 'paused')};
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    animation-delay: ${slideShowDelay};
  }
`;

const Logo = styled.div<{ src: string, clickable: boolean }>`
  background-image: ${({ src }) => `url(${src})`} ;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 70px;
  width: 170px;
  padding: 0 30px 0 30px;
  margin: 0 50px 0 50px;
  transition: transform 200ms ease-in-out;
  
  :hover {
    transform: ${({ clickable }) => (clickable ? 'scale(1.2)' : 'scale(1)')};
  }
`;


const LogoSlider = () => {
  const [isVisible, setIsVisible] = useState(false);
  const logosList = useLogoList();

  const onChange = (visibility: boolean) => {
    setIsVisible(visibility);
  };

  return (
    <VisibilitySensor
      onChange={onChange}
      partialVisibility
      offset={{ top: 50 }}
    >
      <SliderWrapper
        animate={isVisible}
      >
        <Slider>
          { /** There is a <Slide/> component duplicated, to make an infinite loop
           of logos without an empty space. This solution allow us to build this carousel only
           with simple css keyframes logic and no JS needed. */}
          <Slide animate={isVisible}>
            {logosList.map(({ publicURL, fileName, externalLink }) => (
              (externalLink !== '') ? (
                <a
                  href={externalLink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Logo
                    key={fileName}
                    src={publicURL}
                    clickable
                  />
                </a>
              ) : (
                <Logo
                  key={fileName}
                  src={publicURL}
                  clickable={false}
                />
              )
            ))} 
          </Slide> 
          <Slide animate={isVisible}>
            {logosList.map(({ publicURL, fileName, externalLink }) => (
              (externalLink !== '') ? (
                <a
                  href={externalLink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Logo
                    key={fileName}
                    src={publicURL}
                    clickable
                  />
                </a>
              ) : (
                <Logo
                  key={fileName}
                  src={publicURL}
                  clickable={false}
                />
              )
            ))} 
          </Slide> 
        </Slider>
        <StyledSlidingHand animate={isVisible} />
      </SliderWrapper>
    </VisibilitySensor>
  );
};

export default LogoSlider;
