import { css } from 'styled-components';


export const inputShadow = css`
  transition: box-shadow 300ms ease-in-out;
  box-shadow: 6px 6px rgba(0,0,0,0.1);
  
  &:focus {
    box-shadow: 8px 8px rgba(0,0,0,0.3);
  }
`;

export const hexAnimation = css`
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  user-select: none;
  border: 0;
  /* stylelint-disable-next-line */
  background-color: transparent; 
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  transition: filter 300ms ease-in-out, transform 300ms ease-in-out; 
  filter: drop-shadow( 4px 4px rgba(35, 35, 35, 0.2));
  
  :hover, :focus {
    filter: drop-shadow( 8px 8px rgba(35, 35, 35, 0.2));
    transform: translate(0, -4px);
  }
  
  :active {
    filter: drop-shadow( 1px 1px rgba(35, 35, 35, 0.2));
    transform: translate(0, 4px);
  }
`;
