import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled, { DefaultTheme } from 'styled-components';
import { reduceToObject } from '../utils/miscelenaus';
import { hexAnimation } from '../sharedStyles';


/* eslint-disable @typescript-eslint/restrict-template-expressions */
// @ts-ignore
const Background = styled<{ $backgroundimage: string, $isGrid: boolean}>(Link)`
  background-image: ${({ $backgroundimage }) => `url(${$backgroundimage})`};
  margin: ${({ $isGrid }) => ($isGrid ? '-66px -23px' : '')};
  color: ${({ theme }) => (theme as DefaultTheme).colors.white};
  text-decoration: none;
  ${hexAnimation};
`;
/* eslint-enable @typescript-eslint/restrict-template-expressions */

type HexInternalLinkType = 'whiteHex' | 'redHex';

interface HexInternalLinkProps {
  children: object | string,
  to: string,
  type: HexInternalLinkType,
  isGrid?: boolean,
  className?: string,
}

const HexInternalLink = ({
  to,
  children,
  type,
  isGrid = false,
  className = '',
}: HexInternalLinkProps) => {
  const data = useStaticQuery<ListQuery<{name: HexInternalLinkType, publicURL: string}>>(graphql`
    query {
      allFile(filter: {name: {in: ["whiteHex", "redHex"]}}) {
        nodes {
          name
          publicURL
        }
      }
    }
  `);

  const backgrounds = reduceToObject<HexInternalLinkType, string>(
    data.allFile.nodes,
    'name',
    'publicURL'
  );

  return (
    // @ts-ignore
    <Background
      to={to}
      $isGrid={isGrid}
      $backgroundimage={backgrounds[type]}
      className={className}
    >
      <span className="redButtonTitle">
        {children}
      </span>
    </Background>
  );
};


export default HexInternalLink;
