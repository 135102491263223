import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';


const StyledArrowDown = styled(Link)<{ backgroundimage: string }>`
  background-image: ${({ backgroundimage }) => `url(${backgroundimage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100px;
  width: 100px;
`;

interface ArrowDownProps {
  to: string,
}

const ArrowDown = ({ to, ...props }: ArrowDownProps) => {
  const data = useStaticQuery(graphql`
    {
      arrowDownSvg: file(name: {eq: "arrowDown"}) {
        publicURL
      }
    }
  `);


  return (
    <StyledArrowDown
      to={to}
      backgroundimage={data.arrowDownSvg.publicURL}
      {...props}
    />
  );
};

export default ArrowDown;
