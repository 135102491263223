import React, { Component } from 'react';
import styled from 'styled-components';


const BackgroundGradient = styled.div`
  background: linear-gradient(
    to left bottom, 
    ${({ theme }) => theme.colors.blue} 50%,
    ${({ theme }) => theme.colors.white} 50%
  );
  width: 260%;
  height: 600px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  pointer-events: none;
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    width: 100%;
    background: linear-gradient(
      to left bottom, 
      ${({ theme }) => theme.colors.blue} 53%, 
      ${({ theme }) => theme.colors.white} 50%
    );
    height: 1100px
  }
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.medium}) {
    height: 1300px;
  }
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.large}) {
    height: 1400px;
  }
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.extraLarge}) {
    height: 1500px;
  }
`;

const GradientBoxTop = styled.div`
  background: linear-gradient(
    -110deg,
    ${({ theme }) => theme.colors.whiteGradient} 21%,
    ${({ theme }) => theme.colors.gradientZero} 0%,
    ${({ theme }) => theme.colors.gradientZero} 30%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    background: linear-gradient(
      -110deg,
      ${({ theme }) => theme.colors.whiteGradient} 27%,
      ${({ theme }) => theme.colors.gradientZero} 0%,
      ${({ theme }) => theme.colors.gradientZero} 30%
    );  
  }
`;

const GradientBoxBottom = styled.div`
  background: linear-gradient(
    -110deg,
    ${({ theme }) => theme.colors.whiteGradient} 30%,
    ${({ theme }) => theme.colors.gradientZero} 25%,
    ${({ theme }) => theme.colors.gradientZero} 25%
  );
  width: 100%;
  height: 0;
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  
  @media( min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    height: 100%;
  }
`;

class ShadowLights extends Component {
  render() {
    return (
      <>
        <BackgroundGradient>
          <GradientBoxTop />
          <GradientBoxBottom />
        </BackgroundGradient>
      </>
    );
  }
}

export default ShadowLights;
