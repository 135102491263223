import React from 'react';
import styled from 'styled-components';


const Content = styled.div<{hasTwoColumns: boolean}>`
  padding: 20px 15px;
  position: relative;
  top: 0;
  transform: translateY(-20px);
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.large}) {
    display: grid;
    grid-template: 150px / auto;
    grid-template: ${({ hasTwoColumns }) => (hasTwoColumns ? '150px / auto auto' : '150px / auto')};
    grid-gap: 10px 50px;
    padding: 30px;
  }
`;

interface IProps {
  children: React.ReactNode,
  hasTwoColumns?: boolean,
}

const WhatWeDoContent = ({ children, hasTwoColumns = false }: IProps) => (
  <Content hasTwoColumns={hasTwoColumns}>
    {children}
  </Content>
);

export default WhatWeDoContent;
