import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';


const BlueHex = styled.div<{ backgroundimage: string }>`
  background-image: ${({ backgroundimage }) => `url(${backgroundimage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  height: 100%;
  padding: 0 100px;
    
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.extraLarge}) {
    padding: 0 90px;
    width: 700px;
    height: 900px
  }
`;

export default (props: object) => {
  const data = useStaticQuery(graphql`
      query {
        hexSvg: file(name: { eq: "blueAboutHex" }) {
          publicURL
        }
      }
    `);

  return (
    <BlueHex backgroundimage={data.hexSvg.publicURL} {...props} />
  );
};
