import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import TrustedUsMd from './TrustedUsMd';
import LogoSlider from './LogoSlider';


const MainContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.blue};
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 50px 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkGray};
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.blue};
    padding-top: 100px;
  }
`;


const TrustedUs = () => {
  const { t } = useTranslation();

  return (
    <MainContainer id="trustedBy">
      <ContentWrapper>
        <h1>
          {t('trustedBy')}
        </h1>
        <TrustedUsMd />
      </ContentWrapper>
      <LogoSlider />
    </MainContainer>
  );
};

export default TrustedUs;
